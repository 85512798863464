import React, { useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';

import { TopBar } from '../components/topBar/TopBar';
import { FormattedMessage } from 'react-intl';
import { OrderDetails } from '../components/orderDetails/OrderDetails';
import { Deliveries } from '../components/deliveries/Deliveries';
import IconList from '../components/customIcons/IconList';
import IconTruck from '../components/customIcons/IconTruck';
import IconLocation from '../components/customIcons/IconLocation';
import { OrderMap } from '../components/orderMap/OrderMap';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

export const MainScreen = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <TopBar />
      <Tabs value={value} onChange={handleChange} className="order-details-main-tabs">
        <Tab
          icon={<IconTruck fill={value === 0 ? '#002B54' : '#605D62'} />}
          label={<FormattedMessage id={'odDeliveriesButton'} defaultMessage={'odDeliveriesButton'} />}
        />
        <Tab
          icon={<IconList fill={value === 1 ? '#002B54' : '#605D62'} />}
          label={<FormattedMessage id={'odDetailsButton'} defaultMessage={'odDetailsButton'} />}
        />
        <Tab
          icon={<IconLocation fill={value === 2 ? '#002B54' : '#605D62'} />}
          label={<FormattedMessage id={'odMapButton'} defaultMessage={'odMapButton'} />}
        />
      </Tabs>

      <CustomTabPanel value={value} index={0}>
        <Deliveries />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <OrderDetails />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <OrderMap />
      </CustomTabPanel>
    </Box>
  );
};
