import React from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

export const OrderDetails = () => {
  const data = {
    order_number: 41084,
    supplier: 'Rosenburg Materials',
    project: 'Highland Homes',
    destination: '514 Wes 8th St',
    product: 'LOC1001/C-33 SEPTIC SANDS',
    start_date: '05/07/2023  8:00 AM',
  };

  return (
    <Paper elevation={0} className="order-details">
      <Box className="order-details-item-container">
        <Typography mt={2} className="order-details-item" gutterBottom>
          <FormattedMessage id={'odOrderNumber'} defaultMessage={'odOrderNumber'} />
        </Typography>
        <span>{data.order_number}</span>
      </Box>
      <Box className="order-details-item-container">
        <Typography mt={2} className="order-details-item" gutterBottom>
          <FormattedMessage id={'odsupplier'} defaultMessage={'odsupplier'} />
        </Typography>
        <span>{data.supplier}</span>
      </Box>
      <Box className="order-details-item-container">
        <Typography mt={2} className="order-details-item" gutterBottom>
          <FormattedMessage id={'odProject'} defaultMessage={'odProject'} />
        </Typography>
        <span>{data.project}</span>
      </Box>
      <Box className="order-details-item-container">
        <Typography mt={2} className="order-details-item" gutterBottom>
          <FormattedMessage id={'odDestination'} defaultMessage={'odDestination'} />
        </Typography>
        <span>{data.destination}</span>
      </Box>
      <Box className="order-details-item-container">
        <Typography mt={2} className="order-details-item" gutterBottom>
          <FormattedMessage id={'odProduct'} defaultMessage={'odProduct'} />
        </Typography>
        <span>{data.product}</span>
      </Box>
      <Box className="order-details-item-container">
        <Typography mt={2} className="order-details-item" gutterBottom>
          <FormattedMessage id={'odStartDateTime'} defaultMessage={'odStartDateTime'} />
        </Typography>
        <span>{data.start_date}</span>
      </Box>
    </Paper>
  );
};
