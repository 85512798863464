import { IntlProvider } from 'react-intl';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import messages from './i18n';

import { TermsAndConditionsModal } from './views/termsAndConditions/TermsAndConditionsModal';
import './index.css';
import { MainScreen } from './views/mainScreen/MainScreen';

const App = () => {
  return (
    <IntlProvider locale={'en-US'} messages={messages['en-US']}>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<TermsAndConditionsModal open="true" />}></Route>
            <Route path="order-details/*" element={<MainScreen />}></Route>
          </Routes>
        </BrowserRouter>
      </div>
    </IntlProvider>
  );
};

export default App;
