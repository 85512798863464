import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { FormattedMessage } from 'react-intl';
import TargetIcon from '../../customIcons/TargetIcon';

export default function TicketCard({ data }) {
  const [ticketStatus, setTicketStatus] = useState();
  const [truckNumber, setTruckNumber] = useState();
  const [address, setTddress] = useState();
  const [time, setTime] = useState();
  const [schedule, setSchedule] = useState();
  const statusColors = {
    early: '#27AF5D',
    delivered: '#27AF5D',
    late: '#D43D2D',
    scheduled: '#AEAAAE',
  };

  useEffect(() => {
    if (data) {
      setTicketStatus(data.status);
      setTruckNumber(data.truckNumber);
      setTddress(data.address);
      setTime(data.time);
      setSchedule(data.schedule);
    }
  }, [data]);

  return (
    <Card sx={{ minWidth: '100%', marginBottom: '30px' }} className="deliveries-card" key={data.id}>
      <CardContent
        className="deliveries-card-content"
        sx={{
          borderTop: `12px solid ${statusColors[ticketStatus]}`,
        }}
      >
        {/* Box Left */}
        <Box sx={{ width: '30%' }}>
          {(ticketStatus === 'early' || ticketStatus === 'late') && (
            <Box
              className="deliveries-card-content-box-left"
              color={statusColors[ticketStatus]}
              sx={{ marginBottom: '10px' }}
            >
              {ticketStatus === 'early' ? (
                <TrendingDownIcon sx={{ marginRight: '10px' }} />
              ) : (
                <TrendingUpIcon sx={{ marginRight: '10px' }} />
              )}
              <Typography>
                {time} {ticketStatus}
              </Typography>
            </Box>
          )}
          <Box className="deliveries-card-content-box-left">
            <TargetIcon style={{ marginRight: '10px' }} />
            {ticketStatus === 'delivered' && (
              <Typography>
                <FormattedMessage id={'delDeliveredAt'} defaultMessage={'delDeliveredAt'} />
                {schedule}
              </Typography>
            )}
            {ticketStatus === 'scheduled' && (
              <Typography>
                <FormattedMessage id={'delScheduledArrival'} defaultMessage={'delScheduledArrival'} />
                {schedule}
              </Typography>
            )}
            {(ticketStatus === 'early' || ticketStatus === 'late') && <Typography>{schedule}</Typography>}
          </Box>
        </Box>

        {/* Box Right */}
        <Box className="deliveries-card-content-box">
          <Box sx={{ textAlign: 'right' }}>
            <Typography gutterBottom sx={{ fontWeight: 100 }} color="text.secondary">
              <FormattedMessage id={'delTruckNumber'} defaultMessage={'delTruckNumber'} /> {truckNumber}
            </Typography>
            <Typography sx={{ fontWeight: 100 }} color="text.secondary">
              {address}
            </Typography>
          </Box>
          <Box>
            <KeyboardArrowRightIcon fontSize="large" sx={{ marginLeft: '10px' }} />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}
