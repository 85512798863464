import React, { useState } from 'react';
import { Box, Paper, Tabs, Tab } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Map } from './map/Map';

function CustomTabPanelMap(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

export const OrderMap = () => {
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <Paper elevation={0} className="deliveries-container">
      <Tabs value={tabValue} onChange={handleChange} className="deliveries-tabs">
        <Tab
          className="deliveries-tab"
          label={
            <>
              <FormattedMessage id={'mapAllTrucksTitle'} defaultMessage={'mapAllTrucksTitle'} />
            </>
          }
        />
        <Tab
          className="deliveries-tab medium-tab"
          label={
            <>
              <FormattedMessage id={'mapInTransitTitle'} defaultMessage={'mapInTransitTitle'} />
            </>
          }
        />
        <Tab
          className="deliveries-tab"
          label={
            <>
              <FormattedMessage id={'mapOnSiteTitle'} defaultMessage={'mapOnSiteTitle'} />
            </>
          }
        />
      </Tabs>

      <CustomTabPanelMap value={tabValue} index={0}>
        <Map key={tabValue} />
      </CustomTabPanelMap>
      <CustomTabPanelMap value={tabValue} index={1}>
        <Map key={tabValue} />
      </CustomTabPanelMap>
      <CustomTabPanelMap value={tabValue} index={2}>
        <Map key={tabValue} />
      </CustomTabPanelMap>
    </Paper>
  );
};
