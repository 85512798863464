import React from 'react';
import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

export const TermsAndConditionsContent = () => {
  return (
    <>
      <Typography align="center" variant="subtitle1" className="tc-bolder-text">
        <FormattedMessage id={'tcContentTitle'} defaultMessage={'tcContentTitle'} />
      </Typography>
      <Typography variant="body1" mt={5}>
        <Typography paragraph="true" className="tc-bolder-text">
          <FormattedMessage id={'tcP1'} defaultMessage={'tcP1'} />
        </Typography>
        <Typography paragraph="true" className="tc-bolder-text">
          <FormattedMessage id={'tcP2'} defaultMessage={'tcP2'} />
        </Typography>
        <Typography paragraph="true" className="tc-bolder-text">
          <FormattedMessage id={'tcP3'} defaultMessage={'tcP3'} />
        </Typography>
        <Typography paragraph="true" className="tc-bolder-text">
          <FormattedMessage id={'tcP4'} defaultMessage={'tcP4'} />
        </Typography>
        <Typography paragraph="true" className="tc-bolder-text">
          <FormattedMessage id={'tcP5'} defaultMessage={'tcP5'} />
        </Typography>
      </Typography>

      {/* Defined Terms */}
      <Typography align="center" variant="subtitle1" mt={2} className="tc-bolder-text" gutterBottom>
        <FormattedMessage id={'dtTitle'} defaultMessage={'dtTitle'} />
      </Typography>

      <Typography paragraph="true" className="tc-lighter-text">
        <span className="tc-bolder-text">
          <FormattedMessage id={'dtP1Subtitle'} defaultMessage={'dtP1Subtitle'} />
        </span>
        <FormattedMessage id={'dtP1Description'} defaultMessage={'dtP1Description'} />
      </Typography>

      <Typography paragraph="true" className="tc-lighter-text">
        <span className="tc-bolder-text">
          <FormattedMessage id={'dtP2Subtitle'} defaultMessage={'dtP2Subtitle'} />
        </span>
        <FormattedMessage id={'dtP2Description'} defaultMessage={'dtP2Description'} />
      </Typography>

      <Typography paragraph="true" className="tc-lighter-text">
        <span className="tc-bolder-text">
          <FormattedMessage id={'dtP3Subtitle'} defaultMessage={'dtP3Subtitle'} />
        </span>
        <FormattedMessage id={'dtP3Description'} defaultMessage={'dtP3Description'} />
      </Typography>

      <Typography paragraph="true" className="tc-lighter-text">
        <span className="tc-bolder-text">
          <FormattedMessage id={'dtP4Subtitle'} defaultMessage={'dtP4Subtitle'} />
        </span>
        <FormattedMessage id={'dtP4Description'} defaultMessage={'dtP4Description'} />
      </Typography>

      <Typography paragraph="true" className="tc-lighter-text">
        <span className="tc-bolder-text">
          <FormattedMessage id={'dtP5Subtitle'} defaultMessage={'dtP5Subtitle'} />
        </span>
        <FormattedMessage id={'dtP5Description'} defaultMessage={'dtP5Description'} />
      </Typography>

      <Typography paragraph="true" className="tc-lighter-text">
        <span className="tc-bolder-text">
          <FormattedMessage id={'dtP6Subtitle'} defaultMessage={'dtP6Subtitle'} />
        </span>
        <FormattedMessage id={'dtP6Description'} defaultMessage={'dtP6Description'} />
      </Typography>

      <Typography paragraph="true" className="tc-lighter-text">
        <span className="tc-bolder-text">
          <FormattedMessage id={'dtP7Subtitle'} defaultMessage={'dtP7Subtitle'} />
        </span>
        <FormattedMessage id={'dtP7Description'} defaultMessage={'dtP7Description'} />
      </Typography>

      <Typography paragraph="true" className="tc-lighter-text">
        <span className="tc-bolder-text">
          <FormattedMessage id={'dtP8Subtitle'} defaultMessage={'dtP8Subtitle'} />
        </span>
        <FormattedMessage id={'dtP8Description'} defaultMessage={'dtP8Description'} />
      </Typography>

      <Typography paragraph="true" className="tc-lighter-text">
        <span className="tc-bolder-text">
          <FormattedMessage id={'dtP9Subtitle'} defaultMessage={'dtP9Subtitle'} />
        </span>
        <FormattedMessage id={'dtP9Description'} defaultMessage={'dtP9Description'} />
      </Typography>

      {/* Tems & Conditions */}
      <Typography mt={2} gutterBottom>
        <Typography paragraph="true" className="tc-bolder-text">
          <FormattedMessage id={'tcNumber1title'} defaultMessage={'tcNumber1title'} />
        </Typography>
        <Typography paragraph="true" className="tc-lighter-text">
          <FormattedMessage id={'tcNumber1PartA'} defaultMessage={'tcNumber1PartA'} />
        </Typography>
        <Typography paragraph="true" className="tc-lighter-text">
          <FormattedMessage id={'tcNumber1PartB'} defaultMessage={'tcNumber1PartB'} />
        </Typography>
      </Typography>

      <Typography mt={2} gutterBottom>
        <Typography paragraph="true" className="tc-bolder-text">
          <FormattedMessage id={'tcNumber2title'} defaultMessage={'tcNumber2title'} />
        </Typography>
        <Typography paragraph="true" className="tc-lighter-text">
          <FormattedMessage id={'tcNumber2PartA'} defaultMessage={'tcNumber2PartA'} />
        </Typography>
      </Typography>

      <Typography mt={2} gutterBottom>
        <Typography paragraph="true" className="tc-bolder-text">
          <FormattedMessage id={'tcNumber3title'} defaultMessage={'tcNumber3title'} />
        </Typography>
        <Typography paragraph="true" className="tc-lighter-text">
          <FormattedMessage id={'tcNumber3PartA'} defaultMessage={'tcNumber3PartA'} />
        </Typography>
        <Typography paragraph="true" className="tc-lighter-text">
          <FormattedMessage id={'tcNumber3PartB'} defaultMessage={'tcNumber3PartB'} />
        </Typography>
        <Typography paragraph="true" className="tc-lighter-text">
          <FormattedMessage id={'tcNumber3PartC'} defaultMessage={'tcNumber3PartC'} />
        </Typography>
      </Typography>

      <Typography mt={2} gutterBottom>
        <Typography paragraph="true" className="tc-bolder-text">
          <FormattedMessage id={'tcNumber4title'} defaultMessage={'tcNumber4title'} />
        </Typography>
        <Typography paragraph="true" className="tc-lighter-text">
          <FormattedMessage id={'tcNumber4PartA'} defaultMessage={'tcNumber4PartA'} />
        </Typography>
        <Typography paragraph="true" className="tc-lighter-text">
          <FormattedMessage id={'tcNumber4PartB'} defaultMessage={'tcNumber4PartB'} />
        </Typography>
        <Typography paragraph="true" className="tc-lighter-text">
          <FormattedMessage id={'tcNumber4PartC'} defaultMessage={'tcNumber4PartC'} />
        </Typography>
      </Typography>

      <Typography mt={2} gutterBottom>
        <Typography paragraph="true" className="tc-bolder-text">
          <FormattedMessage id={'tcNumber5title'} defaultMessage={'tcNumber5title'} />
        </Typography>
        <Typography paragraph="true" className="tc-lighter-text">
          <FormattedMessage id={'tcNumber5PartA'} defaultMessage={'tcNumber5PartA'} />
        </Typography>
        <Typography paragraph="true" className="tc-lighter-text">
          <FormattedMessage id={'tcNumber5PartB'} defaultMessage={'tcNumber5PartB'} />
        </Typography>
      </Typography>

      <Typography mt={2} gutterBottom>
        <Typography paragraph="true" className="tc-bolder-text">
          <FormattedMessage id={'tcNumber6title'} defaultMessage={'tcNumber6title'} />
        </Typography>
        <Typography paragraph="true" className="tc-lighter-text">
          <FormattedMessage id={'tcNumber6PartA'} defaultMessage={'tcNumber6PartA'} />
        </Typography>
        <Typography paragraph="true" className="tc-lighter-text">
          <FormattedMessage id={'tcNumber6PartB'} defaultMessage={'tcNumber6PartB'} />
        </Typography>
        <Typography paragraph="true" className="tc-lighter-text">
          <FormattedMessage id={'tcNumber6PartC'} defaultMessage={'tcNumber6PartC'} />
        </Typography>
      </Typography>

      <Typography mt={2} gutterBottom>
        <Typography paragraph="true" className="tc-bolder-text">
          <FormattedMessage id={'tcNumber7title'} defaultMessage={'tcNumber7title'} />
        </Typography>
        <Typography paragraph="true" className="tc-lighter-text">
          <FormattedMessage id={'tcNumber7PartA'} defaultMessage={'tcNumber7PartA'} />
        </Typography>
        <Typography paragraph="true" className="tc-lighter-text">
          <FormattedMessage id={'tcNumber7PartB'} defaultMessage={'tcNumber7PartB'} />
        </Typography>
        <Typography paragraph="true" className="tc-lighter-text">
          <FormattedMessage id={'tcNumber7PartC'} defaultMessage={'tcNumber7PartC'} />
        </Typography>
        <Typography paragraph="true" className="tc-lighter-text">
          <FormattedMessage id={'tcNumber7PartD'} defaultMessage={'tcNumber7PartD'} />
        </Typography>
        <Typography paragraph="true" className="tc-lighter-text">
          <FormattedMessage id={'tcNumber7PartE'} defaultMessage={'tcNumber7PartE'} />
        </Typography>
      </Typography>

      <Typography mt={2} gutterBottom>
        <Typography paragraph="true" className="tc-bolder-text">
          <FormattedMessage id={'tcNumber8title'} defaultMessage={'tcNumber8title'} />
        </Typography>
        <Typography paragraph="true" className="tc-lighter-text">
          <FormattedMessage id={'tcNumber8PartA'} defaultMessage={'tcNumber8PartA'} />
        </Typography>
        <Typography paragraph="true" className="tc-lighter-text">
          <FormattedMessage id={'tcNumber8PartB'} defaultMessage={'tcNumber8PartB'} />
        </Typography>
        <Typography paragraph="true" className="tc-lighter-text">
          <FormattedMessage id={'tcNumber8PartC'} defaultMessage={'tcNumber8PartC'} />
        </Typography>
      </Typography>

      <Typography mt={2} gutterBottom>
        <Typography paragraph="true" className="tc-bolder-text">
          <FormattedMessage id={'tcNumber9title'} defaultMessage={'tcNumber9title'} />
        </Typography>
        <Typography paragraph="true" className="tc-lighter-text">
          <FormattedMessage id={'tcNumber9PartA'} defaultMessage={'tcNumber9PartA'} />
        </Typography>
        <Typography paragraph="true" className="tc-lighter-text">
          <FormattedMessage id={'tcNumber9PartB'} defaultMessage={'tcNumber9PartB'} />
        </Typography>
        <Typography paragraph="true" className="tc-lighter-text">
          <FormattedMessage id={'tcNumber9PartC'} defaultMessage={'tcNumber9PartC'} />
        </Typography>
        <Typography paragraph="true" className="tc-lighter-text">
          <FormattedMessage id={'tcNumber9PartD'} defaultMessage={'tcNumber9PartD'} />
        </Typography>
        <Typography paragraph="true" className="tc-lighter-text">
          <FormattedMessage id={'tcNumber9PartE'} defaultMessage={'tcNumber9PartE'} />
        </Typography>
      </Typography>

      <Typography mt={2} gutterBottom>
        <Typography paragraph="true" className="tc-bolder-text">
          <FormattedMessage id={'tcNumber10title'} defaultMessage={'tcNumber10title'} />
        </Typography>
        <Typography paragraph="true" className="tc-lighter-text">
          <FormattedMessage id={'tcNumber10PartA'} defaultMessage={'tcNumber10PartA'} />
        </Typography>
        <Typography paragraph="true" className="tc-lighter-text">
          <FormattedMessage id={'tcNumber10PartB'} defaultMessage={'tcNumber10PartB'} />
        </Typography>
      </Typography>

      <Typography mt={2} gutterBottom>
        <Typography paragraph="true" className="tc-bolder-text">
          <FormattedMessage id={'tcNumber11title'} defaultMessage={'tcNumber11title'} />
        </Typography>
        <Typography paragraph="true" className="tc-lighter-text">
          <FormattedMessage id={'tcNumber11PartA'} defaultMessage={'tcNumber11PartA'} />
        </Typography>
      </Typography>

      <Typography mt={2} gutterBottom>
        <Typography paragraph="true" className="tc-bolder-text">
          <FormattedMessage id={'tcNumber12title'} defaultMessage={'tcNumber12title'} />
        </Typography>
        <Typography paragraph="true" className="tc-lighter-text">
          <FormattedMessage id={'tcNumber12PartA'} defaultMessage={'tcNumber12PartA'} />
        </Typography>
      </Typography>

      <Typography mt={2} gutterBottom>
        <Typography paragraph="true" className="tc-bolder-text">
          <FormattedMessage id={'tcNumber13title'} defaultMessage={'tcNumber13title'} />
        </Typography>
        <Typography paragraph="true" className="tc-lighter-text">
          <FormattedMessage id={'tcNumber13PartA'} defaultMessage={'tcNumber13PartA'} />
        </Typography>
      </Typography>

      <Typography mt={2} gutterBottom>
        <Typography paragraph="true" className="tc-bolder-text">
          <FormattedMessage id={'tcNumber14title'} defaultMessage={'tcNumber14title'} />
        </Typography>
        <Typography paragraph="true" className="tc-lighter-text">
          <FormattedMessage id={'tcNumber14PartA'} defaultMessage={'tcNumber14PartA'} />
        </Typography>
        <Typography paragraph="true" className="tc-lighter-text">
          <FormattedMessage id={'tcNumber14PartB'} defaultMessage={'tcNumber14PartB'} />
        </Typography>
        <Typography paragraph="true" className="tc-lighter-text">
          <FormattedMessage id={'tcNumber14PartC'} defaultMessage={'tcNumber14PartC'} />
        </Typography>
        <Typography paragraph="true" className="tc-lighter-text">
          <FormattedMessage id={'tcNumber14PartD'} defaultMessage={'tcNumber14PartD'} />
        </Typography>
        <Typography paragraph="true" className="tc-lighter-text">
          <FormattedMessage id={'tcNumber14PartE'} defaultMessage={'tcNumber14PartE'} />
        </Typography>
      </Typography>

      <Typography mt={2} gutterBottom>
        <Typography paragraph="true" className="tc-bolder-text">
          <FormattedMessage id={'tcNumber15title'} defaultMessage={'tcNumber15title'} />
        </Typography>
        <Typography paragraph="true" className="tc-lighter-text">
          <FormattedMessage id={'tcNumber15PartA'} defaultMessage={'tcNumber15PartA'} />
        </Typography>
        <Typography paragraph="true" className="tc-lighter-text">
          <FormattedMessage id={'tcNumber15PartB'} defaultMessage={'tcNumber15PartB'} />
        </Typography>
      </Typography>

      <Typography mt={2} gutterBottom>
        <Typography paragraph="true" className="tc-bolder-text">
          <FormattedMessage id={'tcNumber16title'} defaultMessage={'tcNumber16title'} />
        </Typography>
        <Typography paragraph="true" className="tc-lighter-text">
          <FormattedMessage id={'tcNumber16PartA'} defaultMessage={'tcNumber16PartA'} />
        </Typography>
      </Typography>

      <Typography mt={2} gutterBottom>
        <Typography paragraph="true" className="tc-bolder-text">
          <FormattedMessage id={'tcNumber17title'} defaultMessage={'tcNumber17title'} />
        </Typography>
        <Typography paragraph="true" className="tc-lighter-text">
          <FormattedMessage id={'tcNumber17PartA'} defaultMessage={'tcNumber17PartA'} />
        </Typography>
        <Typography paragraph="true" className="tc-lighter-text">
          <FormattedMessage id={'tcNumber17PartB'} defaultMessage={'tcNumber17PartB'} />
        </Typography>
        <Typography paragraph="true" className="tc-lighter-text">
          <FormattedMessage id={'tcNumber17PartC'} defaultMessage={'tcNumber17PartC'} />
        </Typography>
      </Typography>

      <Typography mt={2} gutterBottom>
        <Typography paragraph="true" className="tc-bolder-text">
          <FormattedMessage id={'tcNumber18title'} defaultMessage={'tcNumber18title'} />
        </Typography>
        <Typography paragraph="true" className="tc-lighter-text">
          <FormattedMessage id={'tcNumber18PartA'} defaultMessage={'tcNumber18PartA'} />
        </Typography>
      </Typography>

      <Typography mt={2} gutterBottom>
        <Typography paragraph="true" className="tc-bolder-text">
          <FormattedMessage id={'tcNumber19title'} defaultMessage={'tcNumber19title'} />
        </Typography>
        <Typography paragraph="true" className="tc-lighter-text">
          <FormattedMessage id={'tcNumber19PartA'} defaultMessage={'tcNumber19PartA'} />
        </Typography>
      </Typography>

      <Typography mt={2} gutterBottom>
        <Typography paragraph="true" className="tc-bolder-text">
          <FormattedMessage id={'tcNumber20title'} defaultMessage={'tcNumber20title'} />
        </Typography>
        <Typography paragraph="true" className="tc-lighter-text">
          <FormattedMessage id={'tcNumber20PartA'} defaultMessage={'tcNumber20PartA'} />
        </Typography>
        <Typography paragraph="true" className="tc-lighter-text">
          <FormattedMessage id={'tcNumber20PartB'} defaultMessage={'tcNumber20PartB'} />
        </Typography>
        <Typography paragraph="true" className="tc-lighter-text">
          <FormattedMessage id={'tcNumber20PartC'} defaultMessage={'tcNumber20PartC'} />
        </Typography>
        <Typography paragraph="true" className="tc-lighter-text">
          <FormattedMessage id={'tcNumber20PartD'} defaultMessage={'tcNumber20PartD'} />
        </Typography>
        <Typography paragraph="true" className="tc-lighter-text">
          <FormattedMessage id={'tcNumber20PartE'} defaultMessage={'tcNumber20PartE'} />
        </Typography>
        <Typography paragraph="true" className="tc-lighter-text">
          <FormattedMessage id={'tcNumber20PartF'} defaultMessage={'tcNumber20PartF'} />
        </Typography>
      </Typography>

      {/* Tems & Conditions Footer*/}
      <Typography align="center" variant="subtitle1" mt={2} className="tc-bolder-text" gutterBottom>
        <FormattedMessage id={'tcFooterTitle'} defaultMessage={'tcFooterTitle'} />
      </Typography>
      <Typography align="center" variant="subtitle2" className="tc-bolder-text" mt={2} gutterBottom>
        <FormattedMessage id={'tcFooterContent1'} defaultMessage={'tcFooterContent1'} />
      </Typography>
      <Typography align="center" variant="subtitle2" className="tc-bolder-text" gutterBottom>
        <FormattedMessage id={'tcFooterContent2'} defaultMessage={'tcFooterContent2'} />
      </Typography>
      <Typography align="center" variant="subtitle2" className="tc-bolder-text" gutterBottom>
        <FormattedMessage id={'tcFooterContent3'} defaultMessage={'tcFooterContent3'} />
      </Typography>
      <Typography align="center" variant="subtitle2" className="tc-bolder-text" gutterBottom>
        <FormattedMessage id={'tcFooterContent4'} defaultMessage={'tcFooterContent4'} />
      </Typography>
      <Typography align="center" variant="subtitle2" className="tc-bolder-text" gutterBottom>
        <FormattedMessage id={'tcFooterContent5'} defaultMessage={'tcFooterContent5'} />
      </Typography>
      <Typography align="center" variant="subtitle2" className="tc-bolder-text" gutterBottom>
        <FormattedMessage id={'tcFooterContent6'} defaultMessage={'tcFooterContent6'} />
      </Typography>

      <Typography align="center" variant="caption" mt={5} gutterBottom>
        <FormattedMessage id={'tcLastModified'} defaultMessage={'tcLastModified'} />
      </Typography>
    </>
  );
};
