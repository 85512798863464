import * as React from 'react';

function IconList(props) {
  return (
    <svg width={30} height={30} viewBox="0 0 24 24" fill="#605D62" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M4 13h1.778v-2H4v2zm0 4h1.778v-2H4v2zm0-8h1.778V7H4v2zm3.556 4H20v-2H7.556v2zm0 4H20v-2H7.556v2zm0-10v2H20V7H7.556zM4 13h1.778v-2H4v2zm0 4h1.778v-2H4v2zm0-8h1.778V7H4v2zm3.556 4H20v-2H7.556v2zm0 4H20v-2H7.556v2zm0-10v2H20V7H7.556z" />
    </svg>
  );
}

export default IconList;
