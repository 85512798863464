import React from 'react';
import { Box, AppBar, Toolbar, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

export const TopBar = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" className="order-details-top-bar">
        <Toolbar className="order-details-tool-bar">
          <Typography align="center" variant="h4" component="div" sx={{ flexGrow: 1 }}>
            <FormattedMessage id={'odMainTitle'} defaultMessage={'odMainTitle'} />
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
