import React, { useState } from 'react';
import { Box, Paper, Tabs, Tab } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import TicketCard from './tabs/TicketCard';

function CustomTabPanelDeliveries(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

export const Deliveries = () => {
  const [tabValue, setTabValue] = useState(0);

  const demoArrivingData = [
    {
      id: 'df5152fg1fg12fg',
      truckNumber: '123',
      address: '10CY In Transit',
      time: '5 mins',
      status: 'early',
      schedule: '6:25 AM ETA',
    },
    {
      id: 'rt11vt11y1y11t',
      truckNumber: '3698',
      address: '10CY In Transit',
      time: '5 mins',
      status: 'early',
      schedule: '6:40 AM ETA',
    },
    {
      id: 'dd0202gf221ty',
      truckNumber: '0251',
      address: '10CY In Transit',
      time: '6 mins',
      status: 'late',
      schedule: '6:55 AM ETA',
    },
  ];
  const demoDeliveredData = [
    {
      truckNumber: '123',
      address: '10CY In Transit',
      time: '5 mins',
      status: 'delivered',
      schedule: '6:25 AM',
    },
    {
      truckNumber: '3698',
      address: '10CY In Transit',
      time: '5 mins',
      status: 'delivered',
      schedule: '6:25 AM',
    },
  ];
  const demoScheduledData = [
    {
      truckNumber: '123',
      address: '10CY In Transit',
      time: '5 mins',
      status: 'scheduled',
      schedule: '6:25 AM',
    },
    {
      truckNumber: '3698',
      address: '10CY In Transit',
      time: '5 mins',
      status: 'scheduled',
      schedule: '6:25 AM',
    },
    {
      truckNumber: '0251',
      address: '10CY In Transit',
      time: '5 mins',
      status: 'scheduled',
      schedule: '6:25 AM',
    },
    {
      truckNumber: '0251',
      address: '10CY In Transit',
      time: '5 mins',
      status: 'scheduled',
      schedule: '6:25 AM',
    },
  ];

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <Paper elevation={0} className="deliveries-container">
      <Tabs value={tabValue} onChange={handleChange} className="deliveries-tabs">
        <Tab
          className="deliveries-tab"
          label={
            <>
              <FormattedMessage id={'delArrivingTitle'} defaultMessage={'delArrivingTitle'} /> (
              {demoArrivingData.length})
            </>
          }
        />
        <Tab
          className="deliveries-tab medium-tab"
          label={
            <>
              <FormattedMessage id={'delDeliveredTitle'} defaultMessage={'delDeliveredTitle'} /> (
              {demoDeliveredData.length})
            </>
          }
        />
        <Tab
          className="deliveries-tab"
          label={
            <>
              <FormattedMessage id={'delScheduledTitle'} defaultMessage={'delScheduledTitle'} /> (
              {demoScheduledData.length})
            </>
          }
        />
      </Tabs>

      <CustomTabPanelDeliveries value={tabValue} index={0}>
        {demoArrivingData.map(item => {
          return <TicketCard data={item} key={item.id} />;
        })}
      </CustomTabPanelDeliveries>
      <CustomTabPanelDeliveries value={tabValue} index={1}>
        {demoDeliveredData.map(item => {
          return <TicketCard data={item} key={item.id} />;
        })}
      </CustomTabPanelDeliveries>
      <CustomTabPanelDeliveries value={tabValue} index={2}>
        {demoScheduledData.map(item => {
          return <TicketCard data={item} key={item.id} />;
        })}
      </CustomTabPanelDeliveries>
    </Paper>
  );
};
